<template>
  <div class="page">
    <swiper :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
      <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%"></div>
      <swiper-slide class="swiper-slide historique">
        <div class="content-slider">
          <div class="title" data-swiper-parallax="-100"><b>Demandes d'annulations de rendez-vous</b></div>

        </div>
      </swiper-slide>
    </swiper>
    <div class=" body">
      <!-- ROW 1-->
      <div class="vx-row cursor-pointer">
          <div class="vx-col md:w-1/3 sm:w-full">
          <statistics-card-line
            hideChart
            class="mb-5 "
            icon="EditIcon"
            icon-right
            :statistic="aTraiter"
            v-on:click.native="getDemandesAnnulations('A traiter')"
            statisticTitle="Demandes à traiter"
            statisticSubTitle ="test"
            color="warning" />
          </div>
          <div class="vx-col md:w-1/3 sm:w-full">
          <statistics-card-line
            hideChart
            class="mb-5"
            icon="CheckSquareIcon"
            icon-right
            :statistic="valide"
            statisticTitle="Demandes validées"
            v-on:click.native="getDemandesAnnulations('Validé')"
            color="success" />
          </div>
          <div class="vx-col md:w-1/3 sm:w-full">
          <statistics-card-line
            hideChart
            class="mb-5"
            icon="XSquareIcon"
            icon-right
            :statistic="refuse"
            statisticTitle="Demandes refusées"
            v-on:click.native="getDemandesAnnulations('Refusé')"
            color="danger" />
          </div>
        </div>

      <!-- Tableau -->
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">

          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ rendezVous.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : rendezVous.length }} of {{ rendezVous.length }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>

                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <vs-input class="mb-4 md:mb-0 mr-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
            <vs-button class="mb-4 md:mb-0" @click="exportDataAsCsv()">Exporter csv</vs-button>
          </div>
        </div>
        <div class="mt-5">
          <vs-tabs>
            <vs-tab  label="Toutes" @click="getRendezVous()" >
            </vs-tab>
          </vs-tabs>
          <div class="tab-text">
            <ag-grid-vue
              ref="agGridTable"
              :gridOptions="gridOptions"
              class="ag-theme-material w-100 my-4 ag-grid-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rendezVous"
              rowSelection="multiple"
              colResizeDefault="shift"
              :animateRows="true"
              :floatingFilter="true"
              :pagination="true"
              :localeText = "localeText"
              :paginationPageSize="paginationPageSize"
              :suppressPaginationPanel="true"
              :enableRtl="$vs.rtl">
            </ag-grid-vue>
            <vs-pagination
              :total="totalPages"
              :max="maxPageNumbers"
              v-model="currentPage" />
          </div>
        </div>
      </vx-card>
    </div>
  </div>

</template>

<script>
import axios from '@/axios';
import { colors } from '../../../../themeConfig';
import moment from 'moment';
import router from '@/router';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import JsonCSV from 'vue-json-csv';
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
import ECharts from 'vue-echarts/components/ECharts';
import store from '@/store/store';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import 'echarts/lib/chart/pie';
import adminAnalytics from '../adminAnalytics.js';

const AG_GRID_LOCALE_FR = {
  filterOoo: 'Filter...',
  equals: 'Égale',
  notEqual: 'Différent de ',
  empty: 'Vide',
  inRange: 'Entre',
  greaterThan: 'Supérieur à',
  lessThan: 'Inférieur à',
  contains: 'Contient',
  notContains: 'Ne contient pas',
  startsWith: 'Commence par',
  endsWith: 'Finit par',
  and: 'ET',
  or: 'Ou',
};
const filterParams = {
  comparator(filterLocalDateAtMidnight, cellValue) {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;
    const datePartsD = dateAsString.split(' ');
    const dateParts = datePartsD[0].split('/');
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1] - 1),
      Number(dateParts[0]),
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
  _monthToNum(date) {
    if (date === undefined || date === null) {
      return null;
    }
    const datePartsD = date.split(' ');
    const dateParts = datePartsD[0].split('/');
    const timeParts = datePartsD[1].split(':');
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1] - 1),
      Number(dateParts[0]),
      Number(timeParts[0]),
      Number(timeParts[1]),
      Number(timeParts[2]),
    );
    return cellDate.getTime();
  },
};

export default {
  components: {
    swiper,
    swiperSlide,
    AgGridVue,
    downloadCsv: JsonCSV,
    StatisticsCardLine,
    ECharts,
    adminAnalytics,
  },
  filters: {
    formDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
      }
    },
    priceFormat(value) {
      if (value) {
        const val = (value / 1).toFixed(2).replace('.', ' ');
        return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} € `;
      }
    },
  },
  data() {
    return {

      // Area charts
      localeText: AG_GRID_LOCALE_FR,
      first: '',
      last: '',
      next: '',
      searchQuery: '',
      idsDemandes: [],
      idLigne: this.$route.query.id,
      gridOptions: {
        onRowClicked(event) {
          router.push({ name: 'admin-cancellation-requests-id', params: { id: event.data.id } });
          // this.details(event.data.id)
        },
        getRowNodeId(data) { return data.id; },
      },
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      csvRdv: [],
      termine: '',
      colors,
      refuse: '',
      valide: '',
      aTraiter: '',
      rendezVous: '',
      columnDefs: [
        {
          headerName: 'Statut',
          field: 'statut',
          filter: false,
          cellRenderer(params) {
            let classe = '';
            switch (params.value) {
              case 'A traiter':
                classe = 'warning';
                break;
              case 'Validé':
                classe = 'success';
                break;
              case 'Refusé':
                classe = 'danger';
                break;
            }
            return `<div class="status"><span class="bg-${classe}">${params.value}</span></div>`;
          },
          width: 90,
        },
        {
          headerName: 'Id',
          field: 'id',
          filter: true,
          width: 80,
          cellRenderer(params) {
            return params.value;
          },
        },
        {
          headerName: 'Id Rdv',
          field: 'rendezVous.id',
          filter: true,
          width: 80,
          cellRenderer(params) {
            return params.value;
          },
        },
        {
          headerName: 'Date de création',
          field: 'dateDemande',
          filter: 'agDateColumnFilter',
          filterParams,
          comparator(date1, date2) {
            const date1Number = filterParams._monthToNum(date1);
            const date2Number = filterParams._monthToNum(date2);
            if (date1Number === null && date2Number === null) {
              return 0;
            }
            if (date1Number === null) {
              return -1;
            }
            if (date2Number === null) {
              return 1;
            }

            return date1Number - date2Number;
          },
          width: 270,
        },
        {
          headerName: 'Nom négociateur',
          field: 'negociateur.lastName',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Prénom négociateur',
          field: 'negociateur.firstName',
          filter: true,
          width: 150,
        },
        {
          headerName: 'email négociateur',
          field: 'negociateur.email',
          filter: true,
          width: 130,
          hide: true,
        },
        {
          headerName: 'Nom Téleconseiller',
          field: 'teleconseiller.lastName',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Prénom Téleconseiller',
          field: 'teleconseiller.firstName',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Annonce',
          field: 'rendezVous.ad.reference',
          filter: true,
          width: 80,
        },
        {
          headerName: 'Vendeur',
          field: 'rendezVous.ad.nomVendeur',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Adresse du bien',
          field: 'rendezVous.ad.adresseBien',
          filter: true,
          width: 300,
        },
        {
          headerName: 'Motif de l\'annulation',
          field: 'motif',
          filter: true,
          width: 200,
        },
        {
          headerName: 'Description',
          field: 'description',
          filter: true,
          width: 200,
        },
        {
          headerName: 'Commentaire',
          field: 'rendezVous.commentaire',
          filter: true,
          width: 200,
        },

      ],
      adminAnalytics,
    };
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    exportDataAsCsv() {
      const params = {
        columnGroups: true,
        allColumns: true,
        fileName: 'Demandes_annulations',
        columnSeparator: ';',
      };
      this.gridApi.exportDataAsCsv(params);
    },
    getDemandesAnnulations(statut) {
      // Les commandes
      let urlTotal = '';
      const { user } = store.state.AppActiveUser;
      if ((this.$store.state.AppActiveUser.user.roles).includes('ROLE_SUPER_ADMIN')) {
        if (statut == null) {
          urlTotal = '/api/demande_annulations';
        } else {
          urlTotal = `/api/demande_annulations?statut=${statut}`;
        }
      } else if (statut == null) {
        urlTotal = `/api/demande_annulations?teleconseiller=${user.id}`;
      } else {
        urlTotal = `/api/demande_annulations?statut=${statut}&teleconseiller=${user.id}`;
      }
      axios.get(urlTotal, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          const arrayRdv = [];
          response.data['hydra:member'].forEach((value) => {
            if (value.hasOwnProperty('dateDemande')) {
              value.dateDemande = moment(String(value.dateDemande)).format('DD/MM/YYYY HH:mm:ss');
            }
            arrayRdv.push(value);
          });
          this.rendezVous = arrayRdv;
        });
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    details(id) {
      router.push({ name: 'details', params: { id } });
    },
  },
  watch: {
    statut(newVal) {
      let statut = '';
      switch (newVal) {
        case 'realise':
          statut = 'Réalisé';
          break;
        case 'astatuer':
          statut = 'A statuer';
          break;
        case 'annules':
          statut = 'Annulé';
          break;
      }
      this.getDemandesAnnulations(statut); // or this.openPopup(newVal) is this suits
    },
  },
  mounted() {
    const { user } = store.state.AppActiveUser;
    let urlValide = '';
    let urlAtraiter = '';
    let urlRefuse = '';
    if ((this.$store.state.AppActiveUser.user.roles).includes('ROLE_SUPER_ADMIN')) {
      urlRefuse = '/api/demande_annulations?statut=Refusé';
      urlAtraiter = '/api/demande_annulations?statut=A traiter';
      urlValide = '/api/demande_annulations?statut=Validé';
    } else {
      urlAtraiter = `/api/demande_annulations?statut=A traiter&teleconseiller=${user.id}`;
      urlRefuse = `/api/demande_annulations?statut=Refusé&teleconseiller=${user.id}`;
      urlValide = `/api/demande_annulations?statut=Validé&teleconseiller=${user.id}`;
    }
    axios.get(urlRefuse, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        this.refuse = String(response.data.length);
      });

    axios.get(urlAtraiter, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        this.aTraiter = String(response.data.length);
      });

    axios.get(urlValide, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        this.valide = String(response.data.length);
      });

    this.getDemandesAnnulations();
    this.gridApi = this.gridOptions.api;

    // Stats
    let url = '';
    // Commandes Par Role et par statut , Si super Admin, donc on aura toutes les commandes, sinon on n'obtient que les commandes du téléconseiller connecté
    if ((this.$store.state.AppActiveUser.user.roles).includes('ROLE_SUPER_ADMIN')) {
      url = '/api/demandes/motif/';
    } else {
      url = `/api/demandes/motif/${this.$store.state.AppActiveUser.user.id}`;
    }
    axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }).then((response) => {
      this.adminAnalytics.pieDemandesAnnulations.series[0].data = response.data;
      const serie = [];
      response.data.forEach((key) => {
        serie.push(key);
      });
      this.adminAnalytics.pieDemandesAnnulations.legend.data = serie;
    });

    let dataIndex = -1;
    const { pie } = this.$refs;
    const dataLen = pie.options.series[0].data.length;
    setInterval(() => {
      pie.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex,
      });
      dataIndex = (dataIndex + 1) % dataLen;
      pie.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex,
      });
      pie.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex,
      });
    }, 1000);
  },
};
</script>
<style>
  .body {
    padding-top: 30px;
  }
  .swiper-slide.historique {
    background: url("../../../assets/images/Slider/historique.png");
    background-size: cover;
  }
  .colors .vx-card__title {
    width: 100%;
    text-align: center;
  }

  .colors .vx-card__title h4 {
    width: 100%;
    padding-bottom: 10px;
    font-size: 38px;
  }

  .container {
    padding: 100px 0;
  }

  .colors .vx-card__title h6 {
    color: #fff !important;
  }

  .colors {
    margin-bottom: 50px
  }
  .ag-cell a {color : #000000}
  a.details  {color : #ffffff !important;}

</style>
